import React, { useEffect, useState } from 'react';
import { Container } from 'styled-bootstrap-grid';
import { colors } from '../theme/constants';
import styled from 'styled-components';
import SeperetorBottom from './Seperator-bottom';
import SeperetorTop from './Seperator-top';
import ContentHeader from './Content-header';
import ButtonLink from './Buttons';

const SectionWrapper = styled.div`
  background: ${colors.gray.blogGray};
  border-color: ${colors.gray.blogGray};
  position: relative;
  color: ${colors.gray.blogGray};
  transition: all 300ms ease-in-out;
  padding-top: 10rem;
  padding-bottom: 10rem;
  z-index: 9;
`;

const EmptySpace = styled.div`
  padding-bottom: 7rem;
`;

export default (props) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        // Hack from https://stackoverflow.com/a/47243409/2217533
        const response = await fetch(
          `https://www.instagram.com/graphql/query?query_id=17888483320059182&variables={"id":"${props.userId}","first":${props.photoCount},"after":null}`,
        );
        const { data } = await response.json();
        const photos = data.user.edge_owner_to_timeline_media.edges.map(
          ({ node }) => {
            const { id } = node;
            const caption = node.edge_media_to_caption.edges[0].node.text;
            const thumbnail = node.thumbnail_resources.find(
              (thumbnail) => thumbnail.config_width === props.thumbnailWidth,
            );
            const {
              src,
              config_width: width,
              config_height: height,
            } = thumbnail;
            const url = `https://www.instagram.com/p/${node.shortcode}`;
            return {
              id,
              caption,
              src,
              width,
              height,
              url,
            };
          },
        );
        setPhotos(photos);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [props.photoCount, props.thumbnailWidth, props.userId]);

  return (
    <SectionWrapper>
      <SeperetorTop />
      <Container>
        <ContentHeader
          title={' مطالب اینستاگرام دکتر محمود سامعی '}
          subtitle={' آنکولوژیست و دارای بورد تخصصى راديوتراپى و انكولوژى'}
        />
        <div className="photos">
          {photos &&
            photos.map(({ src, url }, index) => (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={url} target="_blank" key={index}>
                <img alt="Dr Sameie" className="photo" src={src} />
              </a>
            ))}
        </div>
        <EmptySpace></EmptySpace>
        <ButtonLink
          center
          link={'https://www.instagram.com/drmahmoodsamei'}
          title={'بازدید از اینستاگرام'}
          bgColor={colors.blue.captionBlue}
          bgColorIcon={colors.blue.buttonIconBlue}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="instagram"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
            ></path>
          </svg>
        </ButtonLink>
      </Container>
      <SeperetorBottom />
    </SectionWrapper>
  );
};
