import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import { useFiles } from '../hooks/files';
import GatsbyImage from 'gatsby-image';

const CarouselLTR = styled(Carousel)`
  direction: ltr;
  * {
    direction: ltr;
  }
`;

const SliderPhoto = () => {
  const { slide1Jpeg, slide2Jpeg, slide3Jpeg } = useFiles();
  return (
    <CarouselLTR
      showArrows={true}
      autoPlay={true}
      showThumbs={false}
      showStatus={false}
      swipeable={true}
      infiniteLoop={true}
      emulateTouch={true}
    >
      <GatsbyImage fluid={slide1Jpeg} />
      <GatsbyImage fluid={slide2Jpeg} />
      <GatsbyImage fluid={slide3Jpeg} />
    </CarouselLTR>
  );
};

export default SliderPhoto;
