import React from 'react';
import styled from 'styled-components';
import SeperetorBottom from '../components/Seperator-bottom';
import { Container } from 'styled-bootstrap-grid';
import ContentHeader from './Content-header';
import { Grid, Cell, colors, device } from '../theme';
import TeaserBox from './Teaser-box';
import { useFiles } from '../hooks/files';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
  padding-top: 5rem;
  padding-bottom: 3rem;

  @media ${device.laptop} {
    padding-top: 25rem;
    padding-bottom: 13rem;
  }
`;

const ParagraphContent = styled.p`
  text-align: justify;
  color: ${colors.gray.headerItemColor};
  padding: 2.6rem 0;
`;

const MainSection = () => {
  const { doctorJpeg } = useFiles();
  return (
    <SectionLayout>
      <Container>
        <ContentHeader
          title={'وبسایت تخصصی رادیوتراپی و شیمی درمانی'}
          subtitle={
            ' از بین بردن یا کوچک کردن بافت های سرطانی و کاهش علائم بیماری'
          }
        />
        <Grid
          rowGap={'1.5rem'}
          columnGap={'3.5rem'}
          rows={1}
          columns={'70% 30%'}
        >
          <Cell>
            <ParagraphContent>
              ستایش پروردگار مهربان را که با لطف و عنایت خویش مرا را در راه
              اندازی این سایت یاری داد تا بخشی از نیرو و تلاش خود را در راه خدمت
              به همنوعان ارائه نمایم. این سایت تخصصی با دقت زیاد و صرف زمان
              فراوان ، مطالب علمی مورد نیاز بیماران عزیز سرطانی را از منابع
              معتبر علمی هم چون NCII، NCCN ، Webmd و Up-to-date گرد آوری و ترجمه
              نموده .است، هدف از برپائی این سایت ایجاد دریچه ای برای ارتباط با
              بیماران و تبادل نظر با محققان و متخصصین علوم پزشکی در رشته رادیو
              تراپی و آنکولوژی می باشد با درود و سپاسگزاری از توجه شما، ورودتان
              به این سایت را خوش آمد می گویم. امیدوارم اطلاعات موجود در این سایت
              مورد استفاده شما واقع گردد. توصیه های بهداشتی و درمانی در این سایت
              جنبه اطلاع رسانی داشته و به هیچوجه جایگزین پزشک معالج شما نمی
              باشد.
            </ParagraphContent>
            {/* <ButtonLink
              title={' داروهای شیمی درمانی'}
              bgColor={colors.blue.captionBlue}
              bgColorIcon={colors.blue.buttonIconBlue}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="hospital"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M128 244v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12zm140 12h40c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12zm-76 84v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm76 12h40c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12zm180 124v36H0v-36c0-6.627 5.373-12 12-12h19.5V85.035C31.5 73.418 42.245 64 55.5 64H144V24c0-13.255 10.745-24 24-24h112c13.255 0 24 10.745 24 24v40h88.5c13.255 0 24 9.418 24 21.035V464H436c6.627 0 12 5.373 12 12zM79.5 463H192v-67c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v67h112.5V112H304v24c0 13.255-10.745 24-24 24H168c-13.255 0-24-10.745-24-24v-24H79.5v351zM266 64h-26V38a6 6 0 0 0-6-6h-20a6 6 0 0 0-6 6v26h-26a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h26v26a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6V96h26a6 6 0 0 0 6-6V70a6 6 0 0 0-6-6z"
                ></path>
              </svg>
            </ButtonLink> */}
          </Cell>
          <Cell>
            <TeaserBox
              title={'بیوگرافی دکتر'}
              link="/about-us"
              img={doctorJpeg}
            ></TeaserBox>
          </Cell>
        </Grid>
      </Container>
      <SeperetorBottom />
    </SectionLayout>
  );
};

export default MainSection;
