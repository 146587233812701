import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

const ImageHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  .opacity {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #efefef;
    background-color: rgba(40, 60, 94, 0.5);
    box-sizing: border-box;
  }
`;

const GatsbyBackgroundImage = (props) => {
  return (
    <ImageHolder>
      {!props.noShadow && <div className="opacity" />}
      <GatsbyImage fluid={props.img} imgStyle={{ objectFit: 'cover' }} />
    </ImageHolder>
  );
};

export default GatsbyBackgroundImage;
