import React from 'react';
import styled from 'styled-components';
import SeperetorBottom from './Seperator-bottom';
import { H1 } from './Title';
import { Container } from 'styled-bootstrap-grid';
import Header from './Header';
import ContentHeader from './Content-header';
import { Grid, Cell, colors } from '../theme';
import FoldedSection from './Section-folded';
import { LINKS } from '../routes';

const Wrapper = styled(Container)`
  /* display: none; */
`;

const FoldedSectionContainer = () => {
  return (
    <Wrapper>
      <Grid
        gap={'1rem'}
        rows={1}
        columns={'repeat(auto-fit, minmax(240px, 1fr))'}
      >
        <Cell>
          <FoldedSection
            rotateYTop
            borderColor={colors.blue.foldedBodyFour}
            backgroundColor={colors.blue.foldedBodyFour}
            title="مراکز ارائه خدمات و درمان"
            to={'#locations'}
            src="/images/icon-tree-white.png"
          />
        </Cell>
        <Cell>
          <FoldedSection
            rotateYBottom
            borderColor={colors.blue.captionBlue}
            backgroundColor={colors.blue.captionBlue}
            title="اطلاعات داروهای شیمی درمانی"
            to={LINKS.medicines}
            src="/images/icon-book-white.png"
          />
        </Cell>
        <Cell>
          <FoldedSection
            rotateYTop
            borderColor={colors.blue.foldedBodyTwo}
            backgroundColor={colors.blue.foldedBodyTwo}
            title="ارتباط و مشاوره با دکتر"
            to={LINKS.contact}
            src="/images/icon-doctor.png"
          />
        </Cell>
        <Cell>
          <FoldedSection
            rotateYBottom
            borderColor={'rgb(8, 142, 255)'}
            backgroundColor={colors.blue.foldedBodyOne}
            title="تعیین وقت و نوبت دهی"
            to={LINKS.appointment}
            src="/images/icon-help-desk.png"
          />
        </Cell>
      </Grid>
    </Wrapper>
  );
};

export default FoldedSectionContainer;
