import React from 'react';
import styled from 'styled-components';
import { Grid, Cell, colors } from '../theme';
import { Container } from 'styled-bootstrap-grid';
import ButtonLink from './Buttons';
import TeaserBox from './Teaser-box';
import ContentHeader from './Content-header';
import { useFiles } from '../hooks/files';

const SectionWrapper = styled.div`
  background: ${colors.blue.footerBackground};
  border-color: ${colors.blue.footerBackground};
  position: relative;
  color: ${colors.blue.footerBackground};
  transition: all 300ms ease-in-out;
  padding-top: 10rem;
  padding-bottom: 10rem;
  z-index: 9;
`;

const ParagraphWrapper = styled.div`
  padding: 3rem 0;
`;
const ParagraphHeader = styled.p`
  margin-bottom: 1rem;
  font-size: 2.4rem;
  color: ${colors.yellow.hoverFooter};
`;

const ParagraphContent = styled.p`
  text-align: justify;
  color: ${colors.white};
`;
const DepartmentsWrapper = styled.div`
  width: 49%;
  max-width: 100%;
`;
const DepartmentsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
`;

const DepartmentsSection = (props) => {
  const { omidPng, navaJpeg } = useFiles();
  return (
    <SectionWrapper id="locations">
      <Container>
        <ContentHeader
          titleColor={colors.yellow.hoverFooter}
          subtitleColor={colors.white}
          title={'مراکز ارائه خدمات و درمان'}
          subtitle={'ویزیت و مشاوره حضوری و غیر حضوری با تعیین وقت قبلی '}
        />
        <Grid
          rowGap={'1.5rem'}
          columnGap={'3.5rem'}
          rows={1}
          columns={'40% 60%'}
        >
          <Cell>
            <ParagraphWrapper>
              <ParagraphHeader>مطب دکتر محمود سامعی</ParagraphHeader>
              <ParagraphHeader>
                کلینیک رادیو تراپی و آنکولوژی امید تهران
              </ParagraphHeader>
              <ParagraphContent>
                ارایه کلیه خدمات رادیوتراپی و شیمی درمانی در مطب دکتر محمود
                سامعی و کلینیک امید تهران با تعیین وقت قبلی با شماره تلفن
                ۰۹۳۵۹۳۵۹۹۴۷
              </ParagraphContent>
              <ParagraphContent>
                ارایه خدمات در بهترین مراکز خصوصی و دولتی در تهران و کرج بر حسب
                تمایل بیمار و شرایط بیمه درمانی و آدرس محل سکونت بیمار.
              </ParagraphContent>
              <ParagraphContent>
                بیمارانی که داری شرایط خاص هستند از تخفیف های ویژه و حمایت مراکز
                خیریه برخوردار میباشند. لطفا بصورت خصوصی پیام ارسال نمایید.
              </ParagraphContent>
            </ParagraphWrapper>
            <ButtonLink
              link={'/appointment'}
              title={'تعیین وقت و نوبت دهی'}
              bgColor={colors.blue.captionBlue}
              bgColorIcon={colors.blue.buttonIconBlue}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="user-nurse"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="white"
                  d="M319.41,320,224,415.39,128.59,320C57.1,323.1,0,381.6,0,453.79A58.21,58.21,0,0,0,58.21,512H389.79A58.21,58.21,0,0,0,448,453.79C448,381.6,390.9,323.1,319.41,320ZM224,304A128,128,0,0,0,352,176V65.82a32,32,0,0,0-20.76-30L246.47,4.07a64,64,0,0,0-44.94,0L116.76,35.86A32,32,0,0,0,96,65.82V176A128,128,0,0,0,224,304ZM184,71.67a5,5,0,0,1,5-5h21.67V45a5,5,0,0,1,5-5h16.66a5,5,0,0,1,5,5V66.67H259a5,5,0,0,1,5,5V88.33a5,5,0,0,1-5,5H237.33V115a5,5,0,0,1-5,5H215.67a5,5,0,0,1-5-5V93.33H189a5,5,0,0,1-5-5ZM144,160H304v16a80,80,0,0,1-160,0Z"
                ></path>
              </svg>
            </ButtonLink>
          </Cell>
          <Cell>
            <DepartmentsContainer>
              <DepartmentsWrapper>
                <TeaserBox
                  title={' برج نوا - مطب دکتر محمود سامعی '}
                  img={navaJpeg}
                ></TeaserBox>
              </DepartmentsWrapper>
              <DepartmentsWrapper>
                <TeaserBox
                  title={' رادیو تراپی و آنکولوژی امید تهران'}
                  img={omidPng}
                ></TeaserBox>
              </DepartmentsWrapper>
            </DepartmentsContainer>
          </Cell>
        </Grid>
        {/* <Grid rows={1} columns={'40% 60%'} columnGap={'3.5rem'}>
          <Cell></Cell>
          <Cell></Cell>
        </Grid> */}
      </Container>
    </SectionWrapper>
  );
};

export default DepartmentsSection;
