import React from 'react';
import Layout from '../components/Layout';
import BgImageSection from '../components/Bg-image-section';
import MainSection from '../components/Main-section';
import SliderPhoto from '../components/SliderPic';
import DepartmentsSection from '../components/Section-departments';
import FoldedSectionContainer from '../components/Section-Folded-container';
import InstagramGallery from '../components/InstagramGallery';
import { useFiles } from '../hooks/files';

const HomePage = () => {
  const { doctorBlueJpeg, slide3Jpeg } = useFiles();
  // This can be retrieved with a GET https://www.instagram.com/web/search/topsearch/?context=blended&query=INSTAGRAM_USERNAME
  const INSTAGRAM_ID = '2005909858';
  const THUMBNAIL_WIDTH = 640;
  const PHOTO_COUNT = 8;
  return (
    <Layout>
      <SliderPhoto />
      <div>
        <FoldedSectionContainer />
        <MainSection />
        <DepartmentsSection />
        <InstagramGallery
          userId={INSTAGRAM_ID}
          thumbnailWidth={THUMBNAIL_WIDTH}
          photoCount={PHOTO_COUNT}
        />
        <BgImageSection img={doctorBlueJpeg} />
      </div>
    </Layout>
  );
};

export default HomePage;
