import React from 'react';
import styled from 'styled-components';
import { colors, device } from '../theme';
import { Link } from 'gatsby';

export const FoldedSectionWrapper = styled.section`
  z-index: 1;
  background-color: transparent;
  position: relative;
  transform: translate(0px, 0);
  display: flex;
  justify-content: center;

  ::before {
    content: ' ';
  }

  @media ${device.laptop} {
    position: absolute;
    transform: translate(0px, -45%);
  }
`;

export const FoldedBody = styled.div`
  border-color: ${(props) => props.borderColor};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${colors.white};
  background-color: ${(props) => props.backgroundColor};
`;

export const FoldedRow = styled.div`
  ::before {
    content: ' ';
  }
`;

export const SeperatorTop = styled.div`
  border-left-width: 28.9rem;
  margin-bottom: 0;
  background-color: inherit;
  border-color: inherit;
  border-style: solid;

  ::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: inherit;
    border-width: 2.5rem 0 0 1rem;
    border-left-width: inherit;
    top: -2.4rem;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: 0;
    position: absolute;
    z-index: 9;
    display: none;
    ${(props) => props.rotateY && `transform: rotateY(180deg);`}

    @media ${device.laptop} {
      display: block;
    }
  }
`;

export const SeperatorBottom = styled.div`
  border-left-width: 28.9rem;
  margin-bottom: 0;
  background-color: inherit;
  border-color: inherit;
  border-style: solid;

  ::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-color: inherit;
    border-width: 2.5rem 0 0 1rem;
    border-left-width: inherit;
    bottom: -2.4rem;
    border-left-color: transparent;
    border-right-color: transparent;
    left: 0;
    position: absolute;
    z-index: 9;
    display: none;
    ${(props) => props.rotateY && `  transform: rotateY(180deg);`}

    @media ${device.laptop} {
      display: block;
    }
  }
`;

export const TeaserBoxFolded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  padding: 1rem;

  text-align: center;

  @media ${device.laptop} {
    min-height: 24.5rem;
    padding: 3rem 2rem;
  }
`;

export const TeserBoxFigure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const TeserBoxContent = styled(Link)`
  padding: 1rem 0 0 0;
  transition: all 100ms ease-in-out;
  text-align: center;
  cursor: pointer;
  color: ${colors.white};
  @media ${device.laptop} {
    padding: 2rem 0 0 0;
  }
`;

export const TeserImg = styled.img`
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
`;

const FoldedSection = (props) => {
  return (
    <FoldedSectionWrapper>
      <FoldedRow>
        <FoldedBody
          borderColor={props.borderColor}
          backgroundColor={props.backgroundColor}
        >
          <SeperatorTop rotateY={props.rotateYTop}></SeperatorTop>
          <TeaserBoxFolded>
            <TeserBoxFigure>
              <TeserImg src={props.src}></TeserImg>
              <TeserBoxContent to={props.to}>{props.title}</TeserBoxContent>
            </TeserBoxFigure>
          </TeaserBoxFolded>
          <SeperatorBottom rotateY={props.rotateYBottom}></SeperatorBottom>
        </FoldedBody>
      </FoldedRow>
    </FoldedSectionWrapper>
  );
};

export default FoldedSection;
