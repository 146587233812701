import { useStaticQuery, graphql } from 'gatsby';

export const useFiles = () => {
  const { allFile } = useStaticQuery(
    graphql`
      {
        allFile(
          filter: {
            relativePath: {
              in: [
                "omid.png"
                "nava.jpg"
                "doctor.jpg"
                "doctor-blue.jpg"
                "doctorblue1.jpg"
                "slideshow-1.jpg"
                "slideshow-2.jpg"
                "slideshow-3.jpg"
                "bg-2.jpg"
                "award1.jpg"
                "award2.jpg"
                "award3.jpg"
                "award4.jpg"
                "about-slider.jpg"
                "medicine-slider.jpg"
                "blog-pills.jpg"
                "blog-slider.jpg"
                "blue-doc-slider.jpg"
                "facilities-about.jpg"
                "map.png"
                "map9.jpg"
              ]
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 1980, cropFocus: CENTER, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    `,
  );

  const getImage = (name) => {
    return allFile.nodes.find((n) => n.childImageSharp.fluid.src.endsWith(name))
      ?.childImageSharp.fluid;
  };

  const omidPng = getImage('omid.png');
  const navaJpeg = getImage('nava.jpg');
  const doctorJpeg = getImage('doctor.jpg');
  const doctorBlueJpeg = getImage('doctor-blue.jpg');
  const slide1Jpeg = getImage('slideshow-1.jpg');
  const slide2Jpeg = getImage('slideshow-2.jpg');
  const slide3Jpeg = getImage('slideshow-3.jpg');
  const bg2 = getImage('bg-2.jpg');
  const award1 = getImage('award1.jpg');
  const award2 = getImage('award2.jpg');
  const award3 = getImage('award3.jpg');
  const award4 = getImage('award4.jpg');
  const aboutSlider = getImage('about-slider.jpg');
  const medicineSlider = getImage('medicine-slider.jpg');
  const blogPills = getImage('blog-pills.jpg');
  const blogSlider = getImage('blog-slider.jpg');
  const faqSlider = getImage('blue-doc-slider.jpg');
  const mapSlider = getImage('map.png');
  const map9Slider = getImage('map9.jpg');
  const facilitiesAbout = getImage('facilities-about.jpg');

  return {
    omidPng,
    navaJpeg,
    doctorJpeg,
    doctorBlueJpeg,
    slide1Jpeg,
    slide2Jpeg,
    slide3Jpeg,
    bg2,
    award1,
    award2,
    award3,
    award4,
    aboutSlider,
    medicineSlider,
    blogPills,
    blogSlider,
    faqSlider,
    mapSlider,
    map9Slider,
    facilitiesAbout,
  };
};
